<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
// import axios from "axios";
export default {
  name: "AllTax",
  components: { DataTableComponent },
  data() {
    return {
      table: {
        modal: "Books List",
        path: "ItemsList",
        headers: [
          {
            text: this.$i18n.t("Student ID"),
            value: "student_id",
          },
          {
            text: this.$i18n.t("Student Name"),
            value: "student_name",
          },
          {
            text: this.$i18n.t("Grade"),
            value: "grade_name",
          },
          {
            text: this.$i18n.t("Class"),
            value: "class_name",
          },
          {
            text: this.$i18n.t("Nationality"),
            value: "nationality",
          },

          { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
        ],
        title: this.$i18n.t("Student"),
        apiEndPoints: {
          list: "warehouses/BooksList",
          // printBook: "warehouses_transactions/printBookListStudent",
          // edit: "taxes/update",
          // delete: "taxes/destroy",
        },

        // editedItem: {
        //   id: "",
        //   name: "",
        //   percentage: "",
        //   notes: "",
        //   active: "",
        //   countries: [],
        // },
        permissions: {
          list: "list-transactions",
          // add: "add-transactions",
          // edit: "edit-transactions",
          // delete: "delete-transactions",
          // print: "print-transactions",
          // view: "view-transactions",
        },
      },
    };
  },
  methods: {},
};
</script>
